const productSlidersContainer = document.querySelectorAll('.products_container')

productSlidersContainer.forEach((item, index) => {
    item.classList.add(`products_container_${index}`)
    item.querySelector('.swiper').setAttribute('id', `product_${index}`)

    new Swiper(`#product_${index}`, {
        slidesPerGroup: 1,
        slidesPerView: 5,
        loop: false,
        speed: 700,
        spaceBetween: '20px',
        grabCursor: true,
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        pauseOnMouseEnter: true,
        navigation: {
            nextEl: `.products_container_${index} .next`,
            prevEl: `.products_container_${index} .prev`
        },
        breakpoints: {
            120: { slidesPerView: 1.3, spaceBetween: 10, },
            500: { slidesPerView: 1.5, spaceBetween: 10, },
            600: { slidesPerView: 2.1, spaceBetween: 10, },
            700: { slidesPerView: 3, spaceBetween: 20, },
            970: { slidesPerView: 4 },
            1300: { slidesPerView: 5 },
        }
    })
})