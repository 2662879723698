import Offcanvas from '@scripts/modules/Offcanvas';

new Offcanvas ({
	selector: '#mobile-menu',
   close: '#mobile-menu .close',
   open: '.burger',
   overlay: '.ov_offcanvas'
})

const menuLevel = document.querySelectorAll('[data-level]')
const menuNav = document.querySelectorAll('[data-nav]')

menuNav.forEach(item => {
   item.addEventListener('click', function () {
      let currentNav = this.dataset.nav
      let currentLevel = [...menuLevel].find(item => item.dataset.level === currentNav) 
      
      for (let menu of menuLevel) 
         menu.classList.remove('show')
      
      if (currentLevel) {
         // currentLevel.style.height = currentLevel.offsetHeight
         console.log(currentLevel.getBoundingClientRect());
         currentLevel.classList.add('show') 
      }
   })
})