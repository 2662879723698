const review = new Swiper('#review', {
    slidesPerGroup: 1,
    slidesPerView: 2,
    loop: false,
    speed: 700,
    spaceBetween: '20px',
    grabCursor: true,
    keyboard: {
        enabled: true,
        onlyInViewport: false,
    },
    pauseOnMouseEnter: true,
    navigation: {
        nextEl: '.review_container .next',
        prevEl: '.review_container .prev'
    },
    breakpoints: {
        120: { slidesPerView: 1, spaceBetween: 10 },
        700: { slidesPerView: 2 },
        970: { slidesPerView: 1 },
        1300: { slidesPerView: 2, spaceBetween: 20 },
    }
})